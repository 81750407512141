var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "payment-type payment-type-nexi px-0 px-sm-auto" },
    [
      _c("div", { staticClass: "nexi-info-label mb-4" }, [
        _vm._v(" " + _vm._s(_vm.$t("payment.paymentTypeRedirect.info")) + " ")
      ]),
      _vm.paymentType.title
        ? _c("div", {
            staticClass: "payment-type-title",
            domProps: { innerHTML: _vm._s(_vm.paymentType.title) }
          })
        : _vm._e(),
      _c("span", {
        staticClass: "descr",
        domProps: { innerHTML: _vm._s(_vm.paymentType.descr) }
      }),
      _c("PrebilledAmount", {
        attrs: {
          mode: _vm.mode,
          paymentTypeId: _vm.paymentType.paymentTypeId,
          "order-id": _vm.orderId
        }
      }),
      _c("PaymentAuthList", {
        attrs: {
          paymentTypeId: _vm.paymentType.paymentTypeId,
          showRememberAlert: false
        },
        on: {
          selectPaymentAuth: _vm.selectPaymentAuth,
          addNewCard: _vm.addNewCard
        }
      }),
      _vm.options.showRememberCard
        ? _c(
            "div",
            { staticClass: "d-flex flex-row align-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { href: "#", color: "primary", small: "" },
                  on: { click: _vm.addNewCard }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("paymentTypeList.addNewCard")) + " "
                  )
                ]
              ),
              _c("v-checkbox", {
                attrs: { label: _vm.$t("paymentTypeList.rememberNewCard") },
                model: {
                  value: _vm.rememberNewCard,
                  callback: function($$v) {
                    _vm.rememberNewCard = $$v
                  },
                  expression: "rememberNewCard"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("ResponseMessage", { attrs: { response: _vm.response } }),
      _vm.mode != "order" && !_vm.options.hidePrevButton
        ? _c(
            "v-row",
            { staticClass: "d-flex justify-end align-center mt-5" },
            [
              !_vm.$vuetify.breakpoint.xs &&
              _vm.options.prevButton &&
              _vm.optionSs.prevButton.type === "link-button"
                ? _c("LinkButton", {
                    class: _vm.options.prevButton.class,
                    attrs: {
                      options: _vm.options.prevButton.options,
                      label: _vm.options.prevButton.label
                    },
                    on: { click: _vm.goBack }
                  })
                : _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        color: "primary",
                        depressed: "",
                        disabled: _vm.loading,
                        large: ""
                      },
                      on: { click: _vm.goBack }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.back")) + " ")]
                  ),
              _vm.options.confirm.show
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-3 mt-sm-0 ml-2 gateway-btn",
                      attrs: { color: "primary", depressed: "", large: "" },
                      on: { click: _vm.buy }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("payment.paymentTypeList.proceed")) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.$te(`paymentTypeList.${_vm.paymentType.gatewayClient}.caption`)
        ? _c("i18n", {
            staticClass: "caption",
            attrs: {
              path: `paymentTypeList.${_vm.paymentType.gatewayClient}.caption`,
              tag: "div"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }